var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        width: _vm.jsonKey === "coin" ? 500 : 900,
        title: "编辑大币种---" + _vm.jsonKey
      },
      on: { cancel: _vm.handleCancel, ok: _vm.handleConfirm },
      model: {
        value: _vm.showPopup,
        callback: function($$v) {
          _vm.showPopup = $$v
        },
        expression: "showPopup"
      }
    },
    [
      _vm.jsonKey !== "coin"
        ? _c("template", { slot: "footer" }, [_c("div")])
        : _vm._e(),
      _vm.jsonKey === "coin"
        ? _c(
            "div",
            { staticClass: "w-100-w" },
            [
              _c(
                "a-select",
                {
                  staticClass: "w-400",
                  attrs: {
                    "filter-option": _vm.untils.filterOption,
                    showSearch: "",
                    placeholder: "请选择正面面文或者无正面面文"
                  },
                  on: { search: _vm.searchBigCoinData },
                  model: {
                    value: _vm.mustParams.coinId,
                    callback: function($$v) {
                      _vm.$set(_vm.mustParams, "coinId", $$v)
                    },
                    expression: "mustParams.coinId"
                  }
                },
                _vm._l(_vm.bigCoinList, function(item, index) {
                  return _c(
                    "a-select-option",
                    { key: index, attrs: { value: item.id } },
                    [
                      _vm._v(
                        _vm._s(
                          item.coinKindName +
                            (item.coinCategory ? "---" + item.coinCategory : "")
                        )
                      )
                    ]
                  )
                }),
                1
              )
            ],
            1
          )
        : _c("div", { staticClass: "w-100-w" }, [
            _c(
              "div",
              { staticClass: "flex-start-center flex-wrap" },
              _vm._l(
                _vm.recogModelLabelJson[_vm.coinType][_vm.jsonKey],
                function(tag, key) {
                  return _c(
                    "a-tag",
                    {
                      key: key,
                      staticClass:
                        "cur-pot mb-5 w-80 h-30 mr-10 font-size-14 text-center color-black",
                      class:
                        tag === _vm.postJson[_vm.jsonKey] ? "color-blue" : "",
                      attrs: {
                        color: tag === _vm.postJson[_vm.jsonKey] ? "blue" : ""
                      },
                      on: {
                        click: function($event) {
                          return _vm.handleConfirm(tag, _vm.jsonKey)
                        }
                      }
                    },
                    [_vm._v(_vm._s(tag))]
                  )
                }
              ),
              1
            )
          ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }