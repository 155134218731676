import {objArrMapObj} from "@/untils/otherEvent"

/** 对版模型特征收集 */
export const duibanModelLabelTableColumns = [
    { title: "原图", align: "center", scopedSlots: { customRender: "itemCutPicSlot" }, width: 500 },
    { title: "裁剪图", align: "center", scopedSlots: { customRender: "itemCutPicImgSlot" }, width: 350 },
    { title: "状态", align: "center", scopedSlots: { customRender: "itemConfirmStatusSlot" }, width: 100 },
    { title: "钱币类型", align: "center", scopedSlots: { customRender: "itemCoinTypeSlot" }, width: 150 },
    { title: "正面面文", align: "center", scopedSlots: { customRender: "itemImgFrontOrBackSlot" }, width: 150 },
    { title: "正反面", align: "center", scopedSlots: { customRender: "itemTagByFrontOrBackSlot" }, width: 120 },
    { title: "清晰度", align: "center", scopedSlots: { customRender: "itemImgQualitySlot" }, width: 120 },
    { title: "角度", align: "center", scopedSlots: { customRender: "itemImgAngleSlot" }, width: 120 },
    { title: "锈迹", align: "center", scopedSlots: { customRender: "itemImgRustStatusSlot" }, width: 120 },
    { title: "标注员", align: "center", scopedSlots: { customRender: "itemModelLabelAdminSlot" }, width: 150 },
    // { title: "特征", align: "center", scopedSlots: { customRender: "itemImgFeatureSlot" }, width: 200 },
    // { title: "会昌", align: "center", scopedSlots: { customRender: "itemImgKaiYuanStatusSlot" }, width: 120 },
    // { title: "铸局", align: "center", scopedSlots: { customRender: "itemImgCreateCoinTypeSlot" }, width: 120 },
    // { title: "坑口", align: "center", scopedSlots: { customRender: "itemImgUnearthedRelicSlot" }, width: 120 },
    { title: "操作", align: "center", scopedSlots: { customRender: "itemAuctionSlot" }, },
]

/** 已标记，未标记 */
export const confirmStatusTypeList = [
    { name: '已标记', value: 1 },
    { name: '不确定', value: -1 },
    { name: '未标记', value: 0 },
    { name: '人工已确认', value: 10 },
]

/** 是否检测 */
export const checkModelStatusList = [
    { name: '已检测', value: 1 },
    { name: '未检测', value: 0 },
]
/** 央视类 */
export const confirmStatusTypeClassType = {
    0: 'color-red',
    '-1': 'color-gray',
    1: 'color-green',
    10: 'color-orange'
}
/** 正反面选择 正反面  1 正面  2 反面 3 侧面*/
export const frontBackSideList = [
    { name: '正面', value: 1 },
    { name: '反面', value: 2 },
    { name: '侧面', value: 3 },
]
/** 清晰度   1 正常  2 模糊  3 反光*/
export const clarityList = [
    { name: '正常', value: 1 },
    { name: '模糊', value: 2 },
]
/** 角度   1 正常  2 倾斜*/
export const angleList = [
    { name: '正常', value: 1 },
    { name: '倾斜', value: 2 },
]
/** 锈迹   1 无锈  2 重锈*/
export const rustList = [
    { name: '正常', value: 1 },
    { name: '重锈', value: 2 },
]

/** 模型标记收集JSON */
export const MODEL_LABEL_MAP_JSON = {
    frontBackSide: frontBackSideList,
    clarity: clarityList,
    angle: angleList,
    rust: rustList,
}

export const confirmStatusTypeMapText = (value) => {
    return objArrMapObj('value', 'name', confirmStatusTypeList)[value]
}

/** 钱币类型 */
export const COIN_TYPE_LIST = [
    { name: '方孔圆钱', value: 'circle-coin' },
    { name: '蚁鼻钱', value: 'ant-coin' },
    { name: '机制币', value: 'machine-coin' },
    { name: '铲钱', value: 'spade-coin' },
    { name: '鱼币', value: 'fish-coin' },
    { name: '贝币', value: 'shell-coin' },
    { name: '刀币', value: 'knife-coin' },
    { name: '纸币', value: 'paper-coin' },
    { name: '银锭', value: 'ingot-coin' },
    { name: '其他钱币', value: 'other-coin' },
    // { name: '文字', value: 'text' },
    // { name: '二维码', value: 'qrcode' },
    // { name: '条形码', value: 'barcode' },
]

export const coinTypeMapText = (value) => {
    return objArrMapObj('value', 'name', COIN_TYPE_LIST)[value]
}

export const POST_DATA_JSON = {
    // 方孔圆钱
    circle_coin: {
        "正面面文": "",
        "正反面": "",
        "清晰度": "",
        "角度": "",
        "特征": "",
        "会昌": "",
        "铸局": "",
        "锈迹": "",
        "坑口": "",
    },
    // 蚁鼻钱
    ant_coin: {
        "正面面文": "",
        "正反面": "",
        "清晰度": "",
        "角度": "",
        "特征": "",
        "锈迹": "",
    },
    // 机制币
    machine_coin: {
        "正面面文": "",
        "正反面": "",
        "清晰度": "",
        "角度": "",
        "特征": "",
        "锈迹": "",
    }
}



/** 方孔圆钱JSON */
export const COIN_TYPE_MODEL_LABEL_JSON_CIRCLE_COIN = {
    "正面面文": [],
    "正反面": ["正面","反面","侧面"],
    "清晰度": ["正常","模糊"],
    "角度": ["正常","倾斜"],
    "特征": ["无","花穿","重轮","移范","兩丝纹","毛边","刻花","结晶","朱砂"],
    "会昌": [
        "晋","蝌蚪文","背星月","背双月","光背","梓","越","永","益","兖","宣","兴",
        "襄","潭","润","平","洛","梁","蓝","荆","京","洪","桂","广","福","鄂","丹",
        "昌"
    ],
    "铸局": ["无","宝泉","宝源","宝浙","宝直","宝巩","宝昌"],
    "锈迹": ["正常", '重锈'],
    "坑口": [
        "无","生坑","熟坑","传世","祈福坑","龙亭坑","武威坑","西溪坑","高邮坑","成都坑",
        "腾冲坑","定州坑","灵石坑","北京坑","邯郸坑","桂林坑","辉县坑","苏州坑","绵阳坑",
        "丹阳坑","菏泽坑","内蒙坑","大同坑"
    ],
}
/** 蚁鼻钱 */
export const COIN_TYPE_MODEL_LABEL_JSON_ANT_COIN = {
    "正面面文": [],
    "正反面": ["正面","反面","侧面"],
    "清晰度": ["正常","模糊"],
    "角度": ["正常","倾斜"],
    "特征": ["无","毛边",],
    "锈迹": ["正常", '重锈'],
}

/** 机制币 */
export const COIN_TYPE_MODEL_LABEL_JSON_MACHINE_COIN = {
    "正面面文": [],
    "正反面": ["正面","反面","侧面"],
    "清晰度": ["正常","模糊"],
    "角度": ["正常","倾斜"],
    "特征": ["环彩","墨戳","纸戳","字戳"],
    "锈迹": ["正常", '重锈'],
}

/** 模型编辑手机JSON */
export const RECOG_MODEL_LABEL_JSON = {
    circle_coin: COIN_TYPE_MODEL_LABEL_JSON_CIRCLE_COIN,
    ant_coin: COIN_TYPE_MODEL_LABEL_JSON_ANT_COIN,
    machine_coin: COIN_TYPE_MODEL_LABEL_JSON_MACHINE_COIN,
}

/** 角度，锈迹，正反面(暂无)，清晰度相关检测 */
export const coin_angle_rust_clarity_check = {
    clarity: { 0: '正常', 1: '模糊' },
    angle: { 0: '正常', 1: '倾斜' },
    rust: { 0: '正常', 1: '重锈' },
}


/** 搜索类型 */
export const search_type_list = [
    { name: '清晰度', value: 'clarity' },
    { name: '角度', value: 'angle' },
    { name: '锈迹', value: 'rust' },
]

/** 是否匹配状态 */
export const label_match_status_list = [
    { name: '匹配', value: 'yes' },
    { name: '不匹配', value: 'not' },
]

/** 排序方式 */
export const label_sort_type_list = [
    { name: '升序', value: 'asc' },
    { name: '降序', value: 'desc' },
]

/** 搜索分数列表 */
export const search_params_score_list = [
    { name: '1.0', value: '1.0' },
    { name: '0.9', value: '0.9' },
    { name: '0.8', value: '0.8' },
    { name: '0.7', value: '0.7' },
    { name: '0.6', value: '0.6' },
    { name: '0.5', value: '0.5' },
    { name: '0.4', value: '0.4' },
    { name: '0.3', value: '0.3' },
    { name: '0.2', value: '0.2' },
    { name: '0.1', value: '0.1' },
    { name: '自定义', value: 'auto', icon: 'edit' },
]

/** 来源
 * 1 标注确认
 * 2 手动上传
 * 3 GB/BC推送
 * 4 QH 推送
 * 5 对版记录推送
 * 30 基础版别推送
 * 40 版侠推送
 * 60 拍品推送
 * 70 有证链推送
 * 80 样本推送
 * 90 大币种训练' */
export const source_type_list_map_name_class_type = {
    1: { name: '标注确认', color: 'color-black' },
    2: { name: '手动上传', color: 'color-black' },
    3: { name: 'GB/BC推送', color: 'color-black' },
    4: { name: 'QH 推送', color: 'color-blue' },
    5: { name: '对版记录推送', color: 'color-red' },
    30: { name: '基础版别推送', color: 'color-blue' },
    40: { name: '版侠推送', color: 'color-gray' },
    60: { name: '拍品推送', color: 'color-red' },
    70: { name: '有证链推送', color: 'color-gray' },
    80: { name: '样本推送', color: 'color-green' },
    90: { name: '大币种训练', color: 'color-blue' },
}
