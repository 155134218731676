var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { width: 500, title: _vm.titleText },
      on: { cancel: _vm.handleCancel, ok: _vm.handleConfirm },
      model: {
        value: _vm.showPopup,
        callback: function($$v) {
          _vm.showPopup = $$v
        },
        expression: "showPopup"
      }
    },
    [
      _c(
        "a-form-model",
        _vm._b({}, "a-form-model", _vm.layout, false),
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "选择搜索类型" } },
            [
              _c(
                "a-select",
                {
                  staticClass: "ml-5 w-200 mb-5",
                  attrs: {
                    showSearch: "",
                    placeholder: "选择搜索参数类型",
                    allowClear: ""
                  },
                  on: {
                    change: function($event) {
                      return _vm.handleChangeSearchType($event)
                    }
                  },
                  model: {
                    value: _vm.params.searchType,
                    callback: function($$v) {
                      _vm.$set(_vm.params, "searchType", $$v)
                    },
                    expression: "params.searchType"
                  }
                },
                _vm._l(_vm.searchTypeList, function(item) {
                  return _c(
                    "a-select-option",
                    { key: item.value, attrs: { value: item.value } },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
                1
              )
            ],
            1
          ),
          _vm.params.searchType
            ? _c(
                "a-form-model-item",
                { attrs: { label: "是否匹配" } },
                [
                  _c(
                    "a-select",
                    {
                      staticClass: "ml-5 w-200 mb-5",
                      attrs: {
                        "filter-option": _vm.untils.filterOption,
                        showSearch: "",
                        placeholder: "选择是否匹配",
                        allowClear: ""
                      },
                      model: {
                        value: _vm.params.match,
                        callback: function($$v) {
                          _vm.$set(_vm.params, "match", $$v)
                        },
                        expression: "params.match"
                      }
                    },
                    _vm._l(_vm.labelMatchStatusList, function(item) {
                      return _c(
                        "a-select-option",
                        { key: item.value, attrs: { value: item.value } },
                        [_vm._v(_vm._s(item.name))]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.params.searchType
            ? _c(
                "a-form-model-item",
                { attrs: { label: "分数排序" } },
                [
                  _c(
                    "a-select",
                    {
                      staticClass: "ml-5 w-200 mb-5",
                      attrs: {
                        "filter-option": _vm.untils.filterOption,
                        showSearch: "",
                        placeholder: "选择排序方式",
                        allowClear: ""
                      },
                      model: {
                        value: _vm.params.sort,
                        callback: function($$v) {
                          _vm.$set(_vm.params, "sort", $$v)
                        },
                        expression: "params.sort"
                      }
                    },
                    _vm._l(_vm.labelSortTypeList, function(item) {
                      return _c(
                        "a-select-option",
                        { key: item.value, attrs: { value: item.value } },
                        [_vm._v(_vm._s(item.name))]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.params.searchType
            ? _c(
                "a-form-model-item",
                { attrs: { label: "分数搜索" } },
                [
                  !_vm.showSelectScoreAuto
                    ? _c(
                        "a-select",
                        {
                          staticClass: "ml-5 w-200 mb-5",
                          attrs: {
                            showSearch: "",
                            placeholder: "分数搜索",
                            dropdownMenuStyle: {
                              "max-height": "600px"
                            },
                            allowClear: ""
                          },
                          on: { change: _vm.handleChangeSelectScore },
                          model: {
                            value: _vm.params.score,
                            callback: function($$v) {
                              _vm.$set(_vm.params, "score", $$v)
                            },
                            expression: "params.score"
                          }
                        },
                        _vm._l(_vm.searchParamsScoreList, function(item) {
                          return _c(
                            "a-select-option",
                            { key: item.value, attrs: { value: item.value } },
                            [
                              _c("span", [_vm._v(_vm._s(item.name))]),
                              item.icon
                                ? _c("a-icon", {
                                    staticStyle: {
                                      color: "#2d8cf0",
                                      "margin-left": "5px"
                                    },
                                    attrs: { type: item.icon }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        }),
                        1
                      )
                    : _c(
                        "div",
                        { staticClass: "w-100-w flex-start-center" },
                        [
                          _c("a-input", {
                            staticClass: "w-200",
                            attrs: {
                              allowClear: "",
                              type: "number",
                              placeholder: "输入分数"
                            },
                            model: {
                              value: _vm.params.score,
                              callback: function($$v) {
                                _vm.$set(_vm.params, "score", $$v)
                              },
                              expression: "params.score"
                            }
                          }),
                          _c(
                            "a-tooltip",
                            { attrs: { placement: "top" } },
                            [
                              _c("template", { slot: "title" }, [
                                _c("span", [_vm._v("关闭自定义输入分数")])
                              ]),
                              _c("a-icon", {
                                staticClass:
                                  "ml-20 mt-5 color-red font-size-24 cur-pot",
                                attrs: { type: "close-circle" },
                                on: { click: _vm.handleCloseAuto }
                              })
                            ],
                            2
                          )
                        ],
                        1
                      )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }