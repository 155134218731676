<template>
  <a-modal
      :width="500"
      v-model="showPopup"
      :title="titleText"
      @cancel="handleCancel"
      @ok="handleConfirm"
  >
    <a-form-model v-bind="layout">
      <a-form-model-item label="选择搜索类型">
        <a-select
            v-model="params.searchType"
            showSearch
            placeholder="选择搜索参数类型"
            allowClear
            class="ml-5 w-200 mb-5"
            @change="handleChangeSearchType($event)"
        >
          <a-select-option
              v-for="item of searchTypeList"
              :key="item.value"
              :value="item.value"
          >{{ item.name }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="是否匹配" v-if="params.searchType">
        <a-select
            v-model="params.match"
            :filter-option="untils.filterOption"
            showSearch
            placeholder="选择是否匹配"
            allowClear
            class="ml-5 w-200 mb-5"
        >
          <a-select-option
              v-for="item of labelMatchStatusList"
              :key="item.value"
              :value="item.value"
          >{{ item.name }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="分数排序" v-if="params.searchType">
        <a-select
            v-model="params.sort"
            :filter-option="untils.filterOption"
            showSearch
            placeholder="选择排序方式"
            allowClear
            class="ml-5 w-200 mb-5"
        >
          <a-select-option
              v-for="item of labelSortTypeList"
              :key="item.value"
              :value="item.value"
          >{{ item.name }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="分数搜索" v-if="params.searchType">
        <a-select
            v-if="!showSelectScoreAuto"
            v-model="params.score"
            showSearch
            placeholder="分数搜索"
            :dropdownMenuStyle="{
            'max-height': '600px'
          }"
            allowClear
            class="ml-5 w-200 mb-5"
            @change="handleChangeSelectScore"
        >
          <a-select-option
              v-for="item of searchParamsScoreList"
              :key="item.value"
              :value="item.value"
          >
            <span>{{ item.name }}</span>
            <a-icon v-if="item.icon" :type="item.icon" style="color: #2d8cf0;margin-left: 5px;"/>
          </a-select-option>
        </a-select>
        <div v-else class="w-100-w flex-start-center">
          <a-input
              allowClear
              type="number"
              v-model="params.score"
              class="w-200"
              placeholder='输入分数'
          ></a-input>
          <a-tooltip placement="top">
            <template slot="title">
              <span>关闭自定义输入分数</span>
            </template>
            <a-icon
                @click="handleCloseAuto"
                type="close-circle"
                class="ml-20 mt-5 color-red font-size-24 cur-pot"
            />
          </a-tooltip>
        </div>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import untils from "@/untils"
import {
  label_match_status_list, label_sort_type_list,
  search_params_score_list,
  search_type_list
} from "@/views/cmsPage/versionManage/duibanModelLabel/_data";
export default {
  components: {},
  data() {
    return {
      untils,
      typeMapTitle: {
        clarity: '清晰度',
        angle: '角度',
        rust: '锈迹'
      },
      matchMapText: {
        yes: '匹配',
        not: '不匹配'
      },
      sortMapText: {
        asc: '升序',
        desc: '降序'
      },
      layout: {
        labelCol: { span: 8 }, //标签占5份  共24份
        wrapperCol: { span: 16 },
      },
      searchTypeList: search_type_list,
      searchParamsScoreList: search_params_score_list,
      labelMatchStatusList: label_match_status_list,
      labelSortTypeList: label_sort_type_list,
      showSelectScoreAuto: false,
      showPopup: false,
      titleText: '其他搜索条件',
      params: {
        searchType: undefined,
        searchTypeText: undefined,
        match: undefined,
        matchText: undefined,
        sort: undefined,
        sortText: undefined,
        score: undefined,
      },
      searchParams: {
        // // 清晰度相关
        // clartiyMatch: undefined,
        // clartiySort: undefined,
        // clartiyScore: undefined,
        // // 角度相关
        // angleMatch: undefined,
        // angleSort: undefined,
        // angleScore: undefined,
        // // 锈迹相关
        // rustMatch: undefined,
        // rustSort: undefined,
        // rustScore: undefined,
      }
    };
  },
  methods: {
    async show(data, otherParams, autoStatus) {
      this.showSelectScoreAuto = autoStatus
      if (otherParams) this.params = otherParams
      this.showPopup = true
    },
    /** 提交数据 */
    async handleConfirm() {
      if (!this.params.searchType) return  this.$message.warning('请选择要搜索的类型')
      if (!this.params.match && !this.params.sort && !this.params.score) return this.$message.warning('搜索条件至少一个')
      if (this.params.score < 0 && this.params.score > 1 ) return this.$message.warning('输入的分数必须是0-1之间')
      if (this.params.searchType === 'clarity') {
        this.searchParams = {
          clartiyMatch: this.params.match,
          clartiySort: this.params.sort,
          clartiyScore: this.params.score,
          // 空的
          angleMatch: undefined,
          angleSort: undefined,
          angleScore: undefined,
          rustMatch: undefined,
          rustSort: undefined,
          rustScore: undefined,
        }
      } else if (this.params.searchType === 'angle') {
        this.searchParams = {
          // 空的
          clartiyMatch: undefined,
          clartiySort: undefined,
          clartiyScore: undefined,
          // 非空
          angleMatch: this.params.match,
          angleSort: this.params.sort,
          angleScore: this.params.score,
          // 空的
          rustMatch: undefined,
          rustSort: undefined,
          rustScore: undefined,
        }
      } else if (this.params.searchType === 'rust') {
        this.searchParams = {
          // 空的
          clartiyMatch: undefined,
          clartiySort: undefined,
          clartiyScore: undefined,
          angleMatch: undefined,
          angleSort: undefined,
          angleScore: undefined,
          // 非空
          rustMatch: this.params.match,
          rustSort: this.params.sort,
          rustScore: this.params.score,
        }
      }
      this.params.searchTypeText = this.typeMapTitle[this.params.searchType]
      this.params.matchText = this.matchMapText[this.params.match]
      this.params.sortText = this.sortMapText[this.params.sort]
      this.$emit('addSearch', {
        searchParams: this.searchParams,
        otherParams: this.params,
        autoStatus: this.showSelectScoreAuto
      })
      this.handleCancel()
    },
    /** 编辑的时候直接修改搜索类型 */
    handleChangeSearchType(e) {
      this.params.match = undefined
      this.params.sort = undefined
      this.params.score = undefined
    },
    /** 选择分数 */
    handleChangeSelectScore(e) {
      if (e === 'auto') {
        this.showSelectScoreAuto = true
        this.params.score = '0.1'
      }
    },
    /** 关闭自定义输入分数 */
    handleCloseAuto() {
      this.params.score = undefined
      this.showSelectScoreAuto = false
    },
    /** 取消 */
    handleCancel() {
      this.showPopup = false
      this.showSelectScoreAuto = false,
      this.params = {
        searchType: undefined,
        searchTypeText: undefined,
        match: undefined,
        matchText: undefined,
        sort: undefined,
        sortText: undefined,
        score: undefined,
      }
      this.searchParams = {}
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
