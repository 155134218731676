<template>
  <a-modal
      :width="jsonKey === 'coin' ? 500 : 900"
      v-model="showPopup"
      :title="`编辑大币种---${jsonKey}`"
      @cancel="handleCancel"
      @ok="handleConfirm"
  >
    <template slot="footer" v-if="jsonKey !== 'coin'">
      <div></div>
    </template>
<!--  大币种修改  -->
    <div class="w-100-w" v-if="jsonKey === 'coin'">
      <a-select
          @search="searchBigCoinData"
          v-model="mustParams.coinId"
          :filter-option="untils.filterOption"
          showSearch
          class="w-400"
          placeholder="请选择正面面文或者无正面面文"
      >
        <a-select-option
            v-for="(item, index) of bigCoinList"
            :key="index"
            :value="item.id"
        >{{ item.coinKindName + (item.coinCategory ? `---${item.coinCategory}` : '' ) }}</a-select-option>
      </a-select>
    </div>
<!--  tag选择  -->
    <div class="w-100-w" v-else>
      <div class="flex-start-center flex-wrap">
        <a-tag
            v-for="(tag, key) in recogModelLabelJson[coinType][jsonKey]"
            :key="key"
            class="cur-pot mb-5 w-80 h-30 mr-10 font-size-14 text-center color-black"
            :class="tag === postJson[jsonKey] ? 'color-blue' : ''"
            :color="tag === postJson[jsonKey] ? 'blue' : ''"
            @click="handleConfirm(tag, jsonKey)"
        >{{ tag }}</a-tag>
      </div>
    </div>
  </a-modal>
</template>
<script>
import {postModelLabelInfoApi} from "@/views/cmsPage/versionManage/duibanModelLabel/_apis"
import { RECOG_MODEL_LABEL_JSON } from "@/views/cmsPage/versionManage/duibanModelLabel/_data"
import untils from "@/untils"
import {getBigCoinList} from "@/axios/apis"
export default {
  components: {},
  data() {
    return {
      untils,
      recogModelLabelJson: RECOG_MODEL_LABEL_JSON,
      showPopup: false,
      jsonKey: '',
      selectedJsonValue: '',
      bigCoinList: [],
      editorId: '',
      coinType: 'circle_coin',
      mustParams: {
        coinId: 0,
        frontBackSide: 0,
        clarity: 0,
        angle: 0,
        rust: 0
      }
    };
  },
  methods: {
    async show(data, jsonKey, mustParams) {
      this.editorId = data.id
      this.coinType = data.coinType
      Object.assign(this.mustParams, mustParams)
      this.jsonKey = jsonKey
      if (jsonKey === 'coin') {
        await this.searchBigCoinData(data.coinName)
      }
      this.showPopup = true
    },
    /** 提交数据 */
    async handleConfirm() {
      if (this.jsonKey === 'coin') {
        if (!this.mustParams.coinId) return this.$message.warn(`请选择【${this.jsonKey}】`)
      }
      this.$loading.show()
      const res = await postModelLabelInfoApi({
        id: this.editorId,
        coinType: this.coinType,
        ...this.mustParams,
      })
      this.$loading.hide()
      if (res.status !== '200') return
      this.$emit('editSuccess', {id: this.editorId,})
      this.handleCancel()
    },
    /** 取消 */
    handleCancel() {
      this.showPopup = false
      this.coinType = 'circle_coin'
      this.mustParams = {
        coinId: 0,
        frontBackSide: 0,
        clarity: 0,
        angle: 0,
        rust: 0
      }
    },
    /** 查询大币种 */
    async searchBigCoinData(str) {
      const res = await getBigCoinList(str)
      this.$loading.hide()
      if (res.status !== '200') return
      const { records } = res.data;
      records.unshift({ coinKindName: '无', id: 0})
      this.bigCoinList = records;
    }
  },
};
</script>

<style lang="scss" scoped>

</style>
